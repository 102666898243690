import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Row, Col } from "react-bootstrap";

import "../Styles/Alltransactions.css";
import "../Styles/Topaccounts.css";
import addresspng1 from '../images/address1.png';

import Search from "./Searchbar";

const Topaccounts = () => {
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();
  const [pagenumber, setPagenumber] = useState(1);
  const [pagelength] = useState(20); // Set a default page length

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`https://rubyexplorer.com/getTopaccounts/${pagenumber}/${pagelength}`);
        const data = await response.json();
        console.log('Fetched transactions:', data.result);
        setTransactions(data.result || []);
      } catch (error) {
        console.error('Error fetching all transactions:', error);
      }
    };
    fetchTransactions();
  }, [pagenumber, pagelength]);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}......${address.slice(-4)}`;
  };

  const handleaddressClick = (address) => {
    navigate(`/address/${address}`);
  };


  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setPagenumber(newPage);
    }
  };

  return (
    <div className="Alltransaction-detail-container">
      <Search />
      <div className="Topaccounts-heading">Top accounts</div>
      <div className="pagination">
        <span className="pagination-page-first" onClick={() => setPagenumber(1)}>
          First
        </span>
        <span className="pagination-button" onClick={() => handlePageChange(pagenumber - 1)}>
          <i className="fas fa-chevron-left"></i>
        </span>
        <span className="pagination-page-number">
          {pagenumber}
        </span>
        <span className="pagination-button-next" onClick={() => handlePageChange(pagenumber + 1)}>
          <i className="fas fa-chevron-right"></i>
        </span>
      </div>
      <div className="Topaccounts-detail">
        <Table>
          <tbody>
            {transactions.map((transaction, index) => (

              <tr key={index}>
                <td>
                  <div className='Topaccounts-index'>
                    {(pagenumber - 1) * pagelength + index + 1}
                  </div>
                </td>
                <td>
                  <td>
                    <div>                <img src={addresspng1} alt="Transaction logo" className="Topaccounts-address-logo1" />
                    </div>
                  </td>
                  <div className='Topaccounts-address' onClick={() => handleaddressClick(transaction.address)} style={{ cursor: "pointer" }}>
                    {transaction.address}
                  </div>

                </td>
                <td>
                  <div className='Topaccounts-balance'>
                    {trimValue(transaction.balance)}
                  </div>

                </td>
                <td>

                  <div className='Topaccounts-Percentage	'>
                    {trimValue(transaction.balance / 271822351) * 100}%
                  </div>

                </td>

              </tr>


            ))}
          </tbody>
        </Table>
      </div>

      <div className="Topaccounts-cards">
        <Table table>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={transaction.address} onClick={() => handleaddressClick(transaction.address)} style={{ cursor: "pointer" }}>
                <td>
                  <div>
                    <img src={addresspng1} alt="block logo" className="Topaccounts-address-logo-mobile" />
                    <span></span>
                    <div className='Topaccounts-address-mobile'>{shortenAddress(25, transaction.address)}</div>
                    <br />

                    <div className="Topaccounts-data-mobile">Value {transaction.balance}</div>
                    <br />
                    <div className="Topaccounts-data-mobile"> Percentage {trimValue(transaction.balance / 271822351) * 100}%</div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>



      </div>

    </div>
  );
};

export default Topaccounts;
