import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import '../Styles/Signup.css'; // Import your CSS file
import logo from '../images/balancelogo.png';
import { jwtDecode } from 'jwt-decode';

const Account = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setMessage('Email and password are required.');
            handleShow();
            return;
        }
        try {
            const response = await fetch('https://rubyexplorer.com/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });
            const data = await response.json();
            console.log("data", data.msg);
            if (response.ok && data.wallet) {
                localStorage.setItem('token', data.token); // Store the token in localStorage
                setMessage('Login successful!');
                navigate(`/Dashboard`);
            } else if (data.wallet === false) {
                setMessage(`wallet not linked: ${data.msg}`);
                navigate(`/Wallet/${email}`);
            } else {
                setMessage(data.msg);
            }
            handleShow();
        } catch (error) {
            setMessage(`Error: ${error.message}`);
            handleShow();
        }
    };
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken.exp * 1000 > Date.now()) {
                    navigate('/Dashboard');
                } else {
                    navigate('/Account');
                }
            } catch (error) {
                console.error('Error decoding token:', error);
                // Handle the error, e.g., by logging out the user or showing an error message
                navigate('/Account');
            }
        } else {
            // Handle the case when there is no token
        }
    }, []);
    


    return (
        <div className='login-details'>
            <div className='login-card'>
                <div className='loginwalletlogo'>
                    <img
                        src={logo}
                        width="15%"
                        height="15%"
                        className="d-inline-block align-top"
                        alt="Logo"
                    />
                </div>
                <div className='Welcome'><strong>Welcome</strong></div>
                <span>Log in to continue to Ruby Wallet.</span>
                <div className='loginform'>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control1"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control1"
                                id="exampleInputPassword1"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {/* <div className='Forget-password'><strong>Forget password?</strong></div> */}
                        <button type="submit" className="btn btn-primary">Continue</button>
                        <div className='dont-account'>Don't have an account?</div>
                        <div className='Sign-up' onClick={() => navigate(`/Signup`)}>
                            <strong>Sign up</strong>
                        </div>
                    </form>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Account;
