import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../Styles/Alladdress.css";
import { Table, Row, Col } from "react-bootstrap";

import success from '../images/success.svg';
import transactionlogo from '../images/transaction.png';
import Block from '../images/Block.png';
import arrow from '../images/arrow.png';
import addresspng from '../images/address.png';
import addresspng1 from '../images/address1.png';
import info from '../images/info.png';
import balancelogo from '../images/balancelogo.png';

import Search from './Searchbar';
import { color } from "chart.js/helpers";

const Alladdress = () => {
  const { address } = useParams();
  const [alladdress, setaddress] = useState(null);

  const navigate = useNavigate();
  const [copyMessage, setCopyMessage] = useState("");
  const [activeTab, setActiveTab] = useState("Transactions");
  const [traceData, setTraceData] = useState(null);
  const [Internaltransactions, setInternalTransactions] = useState([]);

  const [pagenumber, setPagenumber] = useState(1);
  const [pagelength] = useState(20); // Set a default page length
  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const response = await fetch(
          `https://rubyexplorer.com/getuserTransction/${address}/${pagenumber}/${pagelength}`
        );
        const data = await response.json();
        setaddress(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBlocks();
  }, [address, pagenumber, pagelength]);

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const response = await fetch(
          `https://rubyexplorer.com/getinternaltrx/${address}/${pagenumber}/${pagelength}`
        );
        const data = await response.json();
        setInternalTransactions(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBlocks();
  }, [address, pagenumber, pagelength]);
  // Render loading state if data is not yet available
  if (!alladdress) {
    return <div>Loading...</div>;
  }


  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };

  const handleBlockClick = (blockNumber) => {
    navigate(`/Block/${blockNumber}`);
  };
  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };
  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}..${address.slice(-4)}`;
  };
  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };


  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };


  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setPagenumber(newPage);
    }
  };

  return (
    <div className="address-detail-container">
      <Search />
      <div className="addressheading">Address details</div>
      
      <div className="alladdress"><img
        src={addresspng1}
        alt="addresspng logo"
        className="alladdresslogo"
      />
        {address}
      </div>
      <div className="alladdress1"><img
        src={addresspng1}
        alt="addresspng logo"
        className="alladdresslogo"
      />
        {shortenAddress(28, address)}
      </div>
      <div className="transaction-detail">
        <span className="key"> <img
          src={info}
          alt="info logo"
          className="infologo"
        />Balance</span>
        <span className="text-value" style={{ color: "#101112CC" }}>  <img
          src={balancelogo}
          alt="info logo"
          className="infologo" />{alladdress.balance} RUBY</span>

        <span className="key"> <img
          src={info}
          alt="miner logo"
          className="infologo"
        />Transactions</span>
        <span className="text-value">{alladdress.trx_length}</span>


        <span className="key"> <img
          src={info}
          alt="miner logo"
          className="infologo"
        />Gas used</span>
        <span className="text-value">{alladdress.Gasused}</span>

        <span className="key"> <img
          src={info}
          alt="miner logo"
          className="infologo"
        />Last balance update</span>
        <span className="text-value">{alladdress.latestBlockNumber}</span>

        <div className="address-tabs">
          {["Transactions", "Token transfers", "Tokens", "Internal txns"].map((tab) => (
            <button
              key={tab}
              className={`address-button ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}


        </div>

        <div className="pagination">
          <span className="pagination-page-first" onClick={() => setPagenumber(1)}>
            First
          </span>
          <span className="pagination-button" onClick={() => handlePageChange(pagenumber - 1)}>
            <i className="fas fa-chevron-left"></i>
          </span>
          <span className="pagination-page-number">
            {pagenumber}
          </span>
          <span className="pagination-button-next" onClick={() => handlePageChange(pagenumber + 1)}>
            <i className="fas fa-chevron-right"></i>
          </span>
        </div>
        {activeTab === "Transactions" && (
                 <div>
                 <div className='Alltransaction-desktop'>
                 <Table>
                   <tbody>
                   {alladdress.result.map((transaction, index) => (
                       <tr key={index}>
                         <td>
                           <div className='Alltransaction-hash' onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>
                             {shortenAddress(12, transaction.hash)}
                           </div>
                           <div className='Alltransaction-time'>
                             {formatTimeAgo(transaction.createdAt)}
                           </div>
                         </td>
                         <td>
                           <div className='Alltransaction-type'>
                             {/* <div className='Alltransaction-status'>
                               coin transfer
                             </div> */}
                             <div className='Alltransaction-status1'>
                               success
                             </div>
                           </div>
                         </td>
                         <td>
                           <div className="Alltransaction-twoaddress">
                             <img src={addresspng} alt="Transaction logo" className="Alltransaction-address-logo" />{" "}
                             <div className="Alltransaction-address">
                               <span onClick={() => handleAlladdressClick(transaction.from)} style={{ cursor: "pointer" }}>
                                 {shortenAddress(5, transaction.from)}
                               </span>
                               <span onClick={() => copyToClipboard(transaction.from)} className="copy-icon" title="Copy From">&#x2398;</span>
                             </div>
                             <img src={addresspng1} alt="Transaction logo" className="Alltransaction-address-logo1" />{" "}
                             <div className="Alltransaction-address1">
                               <span onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }}>
                                 {shortenAddress(5, transaction.to)}
                               </span>
                               <span onClick={() => copyToClipboard(transaction.to)} className="copy-icon" title="Copy To">&#x2398;</span>
                             </div>
                           </div>
                         </td>
                         <td>
                           <div className='block-number'>{transaction.blockNumber}</div>
                         </td>
                         <td>
                           <div className='Alltransaction-transfer'>transfer</div>
                         </td>
                        
                         <td>
                           <div className="Alltransaction-amount">
                             {trimValue(transaction.value)}<span className="Alltransaction-unit"> Value</span>
                           </div>
                           <div className="Alltransaction-fee">
                             {trimValue(transaction.fee)}<span className="Alltransaction-unit"> Fee</span>
                           </div>
                         </td>
                       </tr>
                     ))}
                   </tbody>
                 </Table>
                 </div>
                 <div className="Alltransaction-cards">
                 <Table table>
                   <tbody>
                   {alladdress.result.map((transaction, index) => (
                       <tr key={transaction.address} style={{ cursor: "pointer" }}>
                          <td>
                           <div>
                           <img src={transactionlogo} alt="block logo" className="Topaccounts-address-logo-mobile" />
                           
                             <div className='Topaccounts-address-mobile'  onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>{shortenAddress(15,transaction.hash)}</div>
                             <div className='Topaccounts-time'>
                             {formatTimeAgo(transaction.createdAt)}
                           </div>
                             <img src={addresspng} alt="block logo" className="Topaccounts-address-logo-mobile" />
                             <div className='Topaccounts-address-mobile'onClick={() => handleAlladdressClick(transaction.from)} style={{ cursor: "pointer" }}>{shortenAddress(5,transaction.from)}</div>
                             <img src={arrow} alt="Transaction logo" className="Topaccounts-arror-logo1" />{" "}
                             <img src={addresspng1} alt="block logo" className="Topaccounts-address1-logo-mobile" />
                             <div className='Topaccounts-address1-mobile'onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }}>{shortenAddress(5,transaction.to)}</div>
                             <br />
                             <div className="Topaccounts-time">transfer
                             </div>
                             <div className="Topaccounts-data-mobile">Value {transaction.value}</div> 
                             <br />
                           <div className="Topaccounts-data-mobile">Fee {transaction.fee}</div>
                           </div>
                         </td>
                       </tr>
                     ))}
                   </tbody>
                 </Table>
       
                 
       
               </div>
                 </div>
        )}

        {activeTab === "Token transfers" && (
          <div className="Token-transfers">No Token transaction</div>
        )}
        {activeTab === "Tokens" && (
          <div className="Tokens"> No Tokens </div>
        )}

        {activeTab === "Internal txns" && (
          <div>
          <div className='Alltransaction-desktop'>
          <Table>
            <tbody>
            {Internaltransactions.result.map((transaction, index) => (
                <tr key={index}>
                  <td>
                    <div className='Alltransaction-hash' onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>
                      {shortenAddress(12, transaction.hash)}
                    </div>
                    <div className='Alltransaction-time'>
                      {formatTimeAgo(transaction.createdAt)}
                    </div>
                  </td>
                  <td>
                    <div className='Alltransaction-type'>
                      {/* <div className='Alltransaction-status'>
                        coin transfer
                      </div> */}
                      <div className='Alltransaction-status1'>
                        success
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="Alltransaction-twoaddress">
                      <img src={addresspng} alt="Transaction logo" className="Alltransaction-address-logo" />{" "}
                      <div className="Alltransaction-address">
                        <span onClick={() => handleAlladdressClick(transaction.contract)} style={{ cursor: "pointer" }}>
                          {shortenAddress(5, transaction.contract)}
                        </span>
                        <span onClick={() => copyToClipboard(transaction.contract)} className="copy-icon" title="Copy From">&#x2398;</span>
                      </div>
                      <img src={addresspng1} alt="Transaction logo" className="Alltransaction-address-logo1" />{" "}
                      <div className="Alltransaction-address1">
                        <span onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }}>
                          {shortenAddress(5, transaction.to)}
                        </span>
                        <span onClick={() => copyToClipboard(transaction.to)} className="copy-icon" title="Copy To">&#x2398;</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='block-number'>{transaction.blockNumber}</div>
                  </td>
                  <td>
                    <div className='Alltransaction-transfer'>Contract Call</div>
                    
                  </td>
                 
                  <td>
                    <div className="Alltransaction-amount">
                      {trimValue(transaction.value)}<span className="Alltransaction-unit"> Value</span>
                    </div>
                    <div className="Alltransaction-fee">
                      {trimValue(transaction.fee)}<span className="Alltransaction-unit"> Fee</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
          <div className="Alltransaction-cards">
          <Table table>
            <tbody>
            {Internaltransactions.result.map((transaction, index) => (
                <tr key={transaction.address} style={{ cursor: "pointer" }}>
                   <td>
                    <div>
                    <img src={transactionlogo} alt="block logo" className="Topaccounts-address-logo-mobile" />
                    
                      <div className='Topaccounts-address-mobile'  onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>{shortenAddress(15,transaction.hash)}</div>
                      <div className='Topaccounts-time'>
                      {formatTimeAgo(transaction.createdAt)}
                    </div>
                      <img src={addresspng} alt="block logo" className="Topaccounts-address-logo-mobile" />
                      <div className='Topaccounts-address-mobile'onClick={() => handleAlladdressClick(transaction.contract)} style={{ cursor: "pointer" }}>{shortenAddress(5,transaction.contract)}</div>
                      <img src={arrow} alt="Transaction logo" className="Topaccounts-arror-logo1" />{" "}
                      <img src={addresspng1} alt="block logo" className="Topaccounts-address1-logo-mobile" />
                      <div className='Topaccounts-address1-mobile'onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }}>{shortenAddress(5,transaction.to)}</div>
                      <br />
                     <div className="Topaccounts-time">Contract call
                    </div>
                      <div className="Topaccounts-data-mobile">Value {transaction.value}</div> 
                      <br />
                    <div className="Topaccounts-data-mobile">Fee {transaction.fee}</div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          

        </div>
          </div>
        )}

      </div>


    </div>
  );
};

export default Alladdress;
