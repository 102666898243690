import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, Container, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Header.css';
import logo from '../images/ruby.svg';
import blockchainheaderlogo from "../images/blockchainheaderlogo.png";
import transactionlogo from "../images/transaction.png";
import Block from "../images/Block.png";
import topaccountlogo from "../images/topaccountlogo.png";
import verifiedcontract from "../images/verifiedcontract.png";
import tokenslogo from "../images/tokenslogo.png";
import piechartlogo from "../images/pie-chart.png";
import apilogo from "../images/apilogo.png";
import accountlogo from "../images/accountlogo.png";
import shutdown from "../images/shutdown.png";
import Deploycontract from "../images/Deploycontract.png";

const Header = () => {
  const [rubyprice, setRubyPrice] = useState({ usdt_price: '...' });
  const [showLogoutMsg, setShowLogoutMsg] = useState(false);
  const [hasToken, setHasToken] = useState(false);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch('https://api.ctskola.io/api/getruby_price');
        const data = await response.json();
        setRubyPrice(data.result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPrice();

    const token = localStorage.getItem('token');
    setHasToken(!!token); // Update hasToken state based on token presence
  }, []);

  const handleShutdown = () => {
    localStorage.removeItem('token');
    setShowLogoutMsg(true);
    setHasToken(false); // Update hasToken state
    setTimeout(() => {
      setShowLogoutMsg(false);
    }, 3000);
  };

  return (
    <div>
      {showLogoutMsg && (
        <Alert variant="success" onClose={() => setShowLogoutMsg(false)} dismissible>
          Successfully logged out!
        </Alert>
      )}
      <div className='notification'>
        <span className='notificationprice'>RUBY ${rubyprice.usdt_price}</span>
        <span className='notificationgas'>| Gas $0.37</span>
      </div>
      <Navbar bg="light" expand="lg">
        <Container className="navbar-container">
          <div className="navbar-header">
            <Navbar.Brand as={Link} to="/" className="logo">
              <img
                src={logo}
                width="110"
                height="30"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Collapse id="basic-navbar-nav" className="navbar-collapse">
            <Nav className="mr-auto">
              <NavDropdown
                title={
                  <span>
                    <img
                      src={blockchainheaderlogo}
                      alt="headertransactionlogo"
                      className="blockchainheaderlogo"
                    /> Blockchain
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/Alltransactions">
                  <img src={transactionlogo} alt="headertransactionlogo" className="headertransactionlogo" /> Transactions
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Allblock">
                  <img src={Block} alt="headertransactionlogo" className="headertransactionlogo" /> Blocks
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Topaccounts">
                  <img src={topaccountlogo} alt="headertransactionlogo" className="headertransactionlogo" /> Top accounts
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Deploycontract">
                  <img src={Deploycontract} alt="headertransactionlogo" className="headertransactionlogo" /> Deploy contract
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/VerifiedRuby">
                  <img src={verifiedcontract} alt="headertransactionlogo" className="headertransactionlogo" /> Verified contracts
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/Tokens">
                <img src={tokenslogo} alt="headertransactionlogo" className="headertransactionlogo" /> Tokens
              </Nav.Link>
              <Nav.Link as={Link} to="/">
                <img src={piechartlogo} alt="headertransactionlogo" className="headertransactionlogo" /> Charts & stats
              </Nav.Link>
              <Nav.Link as={Link} to="/Api">
                <img src={apilogo} alt="headertransactionlogo" className="headertransactionlogo" /> API
              </Nav.Link>
              <Nav.Link as={Link} to="/Account">
                <img src={accountlogo} alt="headertransactionlogo" className="headertransactionlogo" /> My Account
              </Nav.Link>
              {hasToken && (
                <Nav.Link as={Link} to="/" onClick={handleShutdown}>
                  <img src={shutdown} alt="headertransactionlogo" className="headertransactionlogo" />
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
