import React from 'react';
import '../Styles/Footer.css'; // Import your CSS file for styling
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-section footerdis">
                    <span>Ruby is a tool for inspecting and analyzing RVM based blockchains. Blockchain explorer for Ruby Networks.</span>
                </div>
                <div className="footer-section footerdis1">
                    <ul>
                        <li style={{  cursor: "pointer"}} onClick={() => navigate('/IssueForm')}>Submit an issue</li>
                        <li style={{  cursor: "pointer"}} onClick={() => navigate('/Featurerequest')}>Feature request</li>
                        <li style={{  cursor: "pointer"}} onClick={() => navigate('/Api')}>Api</li>
                        <li>Gas Tracker</li>
                        
                    </ul>
                </div>
                <div className="footer-section footerdis2">
                    <ul>
                    <li style={{  cursor: "pointer"}} onClick={() => navigate('/Alltransactions')}>Transaction</li>
                    <li style={{  cursor: "pointer"}} onClick={() => navigate('/AllBlock')}>Block</li>
                        <li style={{  cursor: "pointer"}} onClick={() => navigate('/Tokens')}>Tokens</li>
                        <li>Charts & stats</li>
                    </ul>
                </div>
            </div>
            <p>&copy; 2024 Ruby scan. All Rights Reserved.</p>
        </footer>
    );
}

export default Footer;
