import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const SendModal = ({ show, handleClose, handleTokenSend }) => {
    const [formData, setFormData] = useState({
        address: '',
        amount: '',
        privateKey: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleTokenSend(formData);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Send Tokens</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formAddress">
                        <Form.Label>Recipient Address</Form.Label>
                        <Form.Control
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formAmount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            type="number"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formNote">
                        <Form.Label>Private Key</Form.Label>
                        <Form.Control
                            type="text"
                            name="privateKey"
                            value={formData.privateKey}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Send
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SendModal;
