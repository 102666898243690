import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Row, Col } from "react-bootstrap";

import "../Styles/Alltransactions.css"; // Import your CSS file for styling
import Search from "./Searchbar";

import '../Styles/TransactionTable.css';
import success from '../images/success.svg';
import transactionlogo from '../images/transaction.png';
import Block from '../images/Block.png';
import arrow from '../images/arrow.png';
import addresspng from '../images/address.png';
import addresspng1 from '../images/address1.png';

const Alltransactions = () => {
  const [twentyFourHoursAgotxn, settwentyFourHoursAgotxn] = useState({
    twentyFourHoursAgotxn: 0,
    totalFees24Hours: 0,
  });

  const [activeTab, setActiveTab] = useState("Validated");
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();
  const [pagenumber, setPagenumber] = useState(1);
  const [pagelength] = useState(20); // Set a default page length
  const [showTable, setShowTable] = useState(false); // State to control table visibility

  useEffect(() => {
    const fetchLastTxn = async () => {
      try {
        const response = await fetch('https://rubyexplorer.com/getHomepagedata');
        const data = await response.json();
        settwentyFourHoursAgotxn(data.result);
      } catch (error) {
        console.error('Error fetching last 24 hours transactions:', error);
      }
    };
    fetchLastTxn();
  }, []);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`https://rubyexplorer.com/getAllTransction/${pagenumber}/${pagelength}`);
        const data = await response.json();

        setTransactions(data.result || []);
        setShowTable(true); // Show table after data is fetched
      } catch (error) {
        console.error('Error fetching all transactions:', error);
      }
    };
    fetchTransactions();
  }, [pagenumber, pagelength]);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}......${address.slice(-4)}`;
  };

  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };

  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setPagenumber(newPage);
    }
  };
  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };
  return (
    <div className="Alltransaction-detail-container">
      <Search />
      <div className="Alltransaction-heading">Ruby transactions</div>
      <div className="Alltransaction-grid">
        <div className="Alltransaction-grid-item">
          <div className="Alltransaction-grid-title">Transactions</div>
          <div className="Alltransaction-grid-value-container">
            <div className="Alltransaction-grid-value">{twentyFourHoursAgotxn.twentyFourHoursAgotxn}</div>
            <div className="Alltransaction-grid-subtitle">(24h)</div>
          </div>
        </div>
        <div className="Alltransaction-grid-item">
          <div className="Alltransaction-grid-title">Pending transactions</div>
          <div className="Alltransaction-grid-value-container">
            <div className="Alltransaction-grid-value">0</div>
            <div className="Alltransaction-grid-subtitle">(1h)</div>
          </div>
        </div>
        <div className="Alltransaction-grid-item">
          <div className="Alltransaction-grid-title">Transaction fees</div>
          <div className="Alltransaction-grid-value-container">
            <div className="Alltransaction-grid-value">{twentyFourHoursAgotxn.totalFees24Hours}</div>
            <div className="Alltransaction-grid-subtitle">(24h)</div>
          </div>
        </div>
        <div className="Alltransaction-grid-item">
          <div className="Alltransaction-grid-title">Avg. transaction fee</div>
          <div className="Alltransaction-grid-value-container">
            <div className="Alltransaction-grid-value">0.0002</div>
            <div className="Alltransaction-grid-subtitle">(24h)</div>
          </div>
        </div>
      </div>
      <div className="Alltransaction-tabs">
        {["Validated", "Pending", "Blob txns"].map((tab) => (
          <button
            key={tab}
            className={`tab-button ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="pagination">
        <span className="pagination-page-first" onClick={() => setPagenumber(1)}>
          First
        </span>
        <span className="pagination-button" onClick={() => handlePageChange(pagenumber - 1)}>
          <i className="fas fa-chevron-left"></i>
        </span>
        <span className="pagination-page-number">
          {pagenumber}
        </span>
        <span className="pagination-button-next" onClick={() => handlePageChange(pagenumber + 1)}>
          <i className="fas fa-chevron-right"></i>
        </span>
      </div>
      <div className='Alltransaction-detail'>
        {activeTab === "Validated" && (
          <div>
            <div className='Alltransaction-desktop'>
              <Table>
                <tbody>
                  {transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>
                        <div className='Alltransaction-hash' onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>
                          {shortenAddress(12, transaction.hash)}
                        </div>
                        <div className='Alltransaction-time'>
                          {formatTimeAgo(transaction.createdAt)}
                        </div>
                      </td>
                      <td>
                        <div className='Alltransaction-type'>
                          {/* <div className='Alltransaction-status'>
                        coin transfer
                      </div> */}
                          <div className='Alltransaction-status1'>
                            success
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="Alltransaction-twoaddress">
                          <img src={addresspng} alt="Transaction logo" className="Alltransaction-address-logo" />{" "}
                          <div className="Alltransaction-address">
                            <span onClick={() => handleAlladdressClick(transaction.transctiontype === 3 ? transaction.contract : transaction.from)}
                          style={{ cursor: "pointer" }}>
                                   {shortenAddress(5, transaction.transctiontype === 3 ? transaction.contract : transaction.from)}
                            </span>

                            
                            <span onClick={() => copyToClipboard(transaction.from)} className="copy-icon" title="Copy From">&#x2398;</span>
                          </div>
                          <img src={addresspng1} alt="Transaction logo" className="Alltransaction-address-logo1" />{" "}
                          <div className="Alltransaction-address1">
                            <span onClick={() => handleAlladdressClick(transaction?.to === '0' ? transaction.contract : transaction.to)} style={{ cursor: "pointer" }}>
9
                            {shortenAddress(5, transaction?.to === '0' ? transaction.contract : transaction.to)}
                         </span>
                            <span onClick={() => copyToClipboard(transaction?.to == '0' ? transaction.contract : transaction.to)} className="copy-icon" title="Copy To">&#x2398;</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='block-number'>{transaction.blockNumber}</div>
                      </td>


                      <td>

                      

                        <div className='Alltransaction-transfer'>
                      {transaction?.transctiontype === 0
                        ? 'Coin transfer'
                        : transaction?.transctiontype === 1
                          ? 'Contract Create'
                          : transaction?.transctiontype === 2
                            ? 'Token transfer'
                            : 'Contract call'}
                    </div>
                      </td>
                      <td>
                        <div className="Alltransaction-amount-fee"> <div className="Alltransaction-amount">
                          {trimValue(transaction.value)}<span className="Alltransaction-unit"> Value</span>
                        </div>
                          <div className="Alltransaction-fee">
                            {trimValue(transaction.fee)}<span className="Alltransaction-unit"> Fee</span>
                          </div></div>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="Alltransaction-cards1">
              <Table table>
                <tbody>
                  {transactions.map((transaction, index) => (
                                        console.log("transaction",transaction.contract),
                    <tr key={transaction.address} style={{ cursor: "pointer" }}>
                      <td>
                        <div>
                          <img src={transactionlogo} alt="block logo" className="Topaccounts-address-logo-mobile" />

                          <div className='Topaccounts-address-mobile' onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>{shortenAddress(15, transaction.hash)}</div>
                          <div className='Topaccounts-time'>
                            {formatTimeAgo(transaction.createdAt)}
                          </div>

                          
                          <img src={addresspng} alt="block logo" className="Topaccounts-address-logo-mobile" />
                          <div className='Topaccounts-address-mobile'onClick={() => handleAlladdressClick(transaction.transctiontype === 3 ? transaction.contract : transaction.from)}
                          style={{ cursor: "pointer" }}>     {shortenAddress(5, transaction.transctiontype === 3 ? transaction.contract : transaction.from)}</div>


                          <img src={arrow} alt="Transaction logo" className="Topaccounts-arror-logo1" />{" "}
                          <img src={addresspng1} alt="block logo" className="Topaccounts-address1-logo-mobile" />
                          <div className='Topaccounts-address1-mobile' onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }}>
                          {shortenAddress(5, transaction?.to === '0' ? transaction.contract : transaction.to)}
                          </div>
                          
                          <br />
                          
                          <div className="Topaccounts-data-mobile">Value {transaction.value}</div>
                          <br />
                          <div className='Topaccounts-time'>
                      {transaction?.transctiontype === 0
                        ? 'Coin transfer'
                        : transaction?.transctiontype === 1
                          ? 'Contract Create'
                          : transaction?.transctiontype === 2
                            ? 'Token transfer'
                            : 'Contract call'}
                    </div>
                          <div className="Topaccounts-data-mobile">Fee {transaction.fee}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>



            </div>
          </div>

        )}
        {activeTab === "Pending" && (
          <div className="pending">No pending transactions found.</div>
        )}
        {activeTab === "Blob txns" && (
          <div className="blob-txns">No blob transactions found.</div>
        )}
      </div>
    </div>
  );
};

export default Alltransactions;
